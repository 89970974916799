import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import {Button, Col, Modal, notification, Row, Space, Spin, Upload} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload/interface'
import {FileParameter, FirmwareUpdateStatus, LockerRequest} from 'axios/client'
import axios from 'axios/instance'
import classNames from 'classnames'
import {Icon} from 'components/icon'
import {DEFAULT_FULL_TIME_FORMAT} from 'config/client'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {UploadedFiles} from './types'

// const FIRMWARE_UPGRADE_TICK_MS = 10000

export interface FirmwareUpgradeData {
  progress?: number
  responseSent?: Date
  status?: FirmwareUpdateStatus
  prevStatus?: FirmwareUpdateStatus
}

// Form props
export interface UpgradeFirmwareFormProps {
  lockerId: string
  disabled: boolean
  // eslint-disable-next-line no-unused-vars
  onLock: (lock: boolean) => void
  onReset: () => void
  // eslint-disable-next-line no-unused-vars
  onConnectionChange: (isConnected: boolean) => void
  onRefresh: () => void
  // eslint-disable-next-line no-unused-vars
  onSuccess: (skipConfirm: boolean) => void
}

// Form component
export const UpgradeFirmwareForm = ({
                                      lockerId,
                                      disabled,
                                      onLock,
                                      onReset,
                                      onConnectionChange,
                                      onRefresh,
                                      onSuccess
                                    }: UpgradeFirmwareFormProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadedFiles>([])
  const [{prevStatus, status, responseSent, progress}, setFirmwareUpgradeData] = useState<FirmwareUpgradeData>({})
  // const [intervalId, setIntervalId] = useState<CurrentInterval>()

  // Memorized data
  const request = useMemo(() => new LockerRequest({lockerId}), [lockerId])

  // Load firmware upgrade status
  const getFirmwareUpgradeStatus = useCallback(() => {
    axios.getFirmwareUpdateStatus(request).then(response => {
      if (response?.result?.toLowerCase() === 'ok')
        setFirmwareUpgradeData((prevState: FirmwareUpgradeData) => ({
          status: response?.state,
          prevStatus: prevState.status,
          progress: response?.firmwareUpdateProgress,
          responseSent: response?.responseSent,
        }))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  // Dragger handlers
  const handleBeforeUpdate = (): boolean => false
  const handleOnChange = (info: UploadChangeParam): void => setFileList(info.fileList)
  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>): void => setFileList(e.dataTransfer.files)
  const handleOnRemoveOrReset = (): void => setFileList([])

  //
  const handleReset = (): void => {
    setFirmwareUpgradeData({})
    onReset()
  }

  const handleRefresh = (): void => {
    setFirmwareUpgradeData({})
    onRefresh()
  }

  // Handle firmware upload
  const handleUpload = (): void => {
    if (fileList) {
      Modal.confirm({
        title: 'Firmware upgrade',
        content: 'The firmware will be uploaded and upgraded',
        onOk: () => {
          setLoading(true)
          axios
            .firmwareUpdate(lockerId, {
              data: fileList[0].originFileObj,
              fileName: fileList[0].name
            } as FileParameter)
            .then(() => setFileList([]))
            .then(() =>
              setFirmwareUpgradeData({
                status: FirmwareUpdateStatus.InProgress,
                progress: 0,
                responseSent: new Date(),
              })
            )
            .then(() => onLock(true))
            .then(() =>
              notification.success({
                message: 'Firmware was uploaded',
                className: 'notify-colored success',
                duration: 4,
              })
            )
            .catch(error =>
              notification.error({
                message: 'Error on firmware upload',
                description: error?.detail ?? null,
                className: 'notify-colored error',
                duration: 4,
              })
            )
            .finally(() => setLoading(false))
        },
      })
    }
  }

  // On firmware upgrade status change
  useEffect(() => {
    // If exists state, try to run timer
    if (status) {
      //
      if (status === FirmwareUpdateStatus.InProgress || status === FirmwareUpdateStatus.Successful) {
        onLock(true)
        if (status === FirmwareUpdateStatus.Successful) {
          onSuccess(true)
        }
      }

      // If upload failed or if state is none try stop timer
      if (status === FirmwareUpdateStatus.ErrorInUpgradeFile || status === FirmwareUpdateStatus.Failed || status === FirmwareUpdateStatus.None) {
        onLock(false)
      }

      // If state is success and prev state in progress - locker is restarting and must be change state
      if (status === FirmwareUpdateStatus.Successful && prevStatus === FirmwareUpdateStatus.InProgress) {
        onConnectionChange(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, prevStatus, request])

  // Show disabled spinner
  if ((loading || disabled) && status !== FirmwareUpdateStatus.Successful) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <Spin
            size={'large'} tip={'Loading'}
            indicator={<LoadingOutlined spin={true} className={classNames('spinner', 'checking')} />}
          />
        </Col>
      </Row>
    )
  }

  // Show checking spinner
  if (!status) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <Spin
            size={'large'} tip={'Unknown status'}
            indicator={<QuestionCircleOutlined className={'spinner checking'} />}
          />
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <small>{'for checking current status use button below'}</small>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-result-btn'}>
          <Button onClick={getFirmwareUpgradeStatus} type={'primary'}>
            {'Get firmware upgrade status'}
          </Button>
        </Col>
      </Row>
    )
  }

  // Show in progress or success spinner
  if (status === FirmwareUpdateStatus.InProgress) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <Spin
            size={'large'} tip={'Firmware upgrade is in progress'}
            indicator={<PlayCircleOutlined className={'spinner in-progress'} />}
          />
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <strong>{`${progress ?? 0}%`}</strong>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <small>{`last status check in ${moment(responseSent).utc().format(DEFAULT_FULL_TIME_FORMAT)} UTC`}</small>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-result-btn'}>
          <Button onClick={getFirmwareUpgradeStatus} type={'primary'}>
            {'Get firmware upgrade status'}
          </Button>
        </Col>
      </Row>
    )
  }

  // Show success spin
  if (status === FirmwareUpdateStatus.Successful) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <Spin
            size={'large'} tip={'Firmware was successfully upgraded and locker is restarting'}
            indicator={<PauseCircleOutlined className={'spinner success'} />}
          />
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <small>{`last status check in ${moment(responseSent).utc().format(DEFAULT_FULL_TIME_FORMAT)} UTC`}</small>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-result-btn'}>
          <Button onClick={getFirmwareUpgradeStatus} type={'primary'}>
            {'Get firmware upgrade status'}
          </Button>
        </Col>
      </Row>
    )
  }

  // Show success result
  if (status === FirmwareUpdateStatus.None && prevStatus === FirmwareUpdateStatus.Successful) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container success'}>
          <CheckCircleOutlined size={50} />
          <span className={'text'}>{'Firmware was successfully upgraded.'}</span>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <small>{`last status check in ${moment(responseSent).utc().format(DEFAULT_FULL_TIME_FORMAT)} UTC`}</small>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-result-btn'}>
          <Button type={'primary'} onClick={handleRefresh}>
            <Icon icon={'sync-alt'} />
            {'Refresh locker'}
          </Button>
        </Col>
      </Row>
    )
  }

  // Show failed result
  if (status === FirmwareUpdateStatus.Failed || status === FirmwareUpdateStatus.ErrorInUpgradeFile) {
    return (
      <Row>
        <Col span={12} offset={6} className={'upload-progress-container failed'}>
          <ExclamationCircleOutlined size={50} />
          <span
            className={'text'}
          >
            {status === FirmwareUpdateStatus.Failed ? 'Firmware upgrade failed' : 'Some error in upgraded firmware'}
          </span>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-container'}>
          <small>{`last status check in ${moment(responseSent).utc().format(DEFAULT_FULL_TIME_FORMAT)} UTC`}</small>
        </Col>
        <Col span={12} offset={6} className={'upload-progress-result-btn'}>
          <Button type={'primary'} danger={true} onClick={handleReset}>
            <Icon icon={'redo-alt'} />
            {'Reset locker'}
          </Button>
        </Col>
      </Row>
    )
  }

  // Render JSX
  return (
    <>
      <Row className={'dragger-container'}>
        <Col span={20} offset={2}>
          <Upload.Dragger
            multiple={false}
            beforeUpload={handleBeforeUpdate}
            onRemove={handleOnRemoveOrReset}
            onChange={handleOnChange}
            onDrop={handleOnDrop}
            disabled={disabled || loading}
            maxCount={1}
          >
            <p className={'ant-upload-drag-icon'}>
              <InboxOutlined />
            </p>
            <p className={'ant-upload-text'}>{'Click or drag file to this area to upload'}</p>
            <p className={'ant-upload-hint'}>{'You can upload only one file'}</p>
          </Upload.Dragger>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={2} className={'upload-button-container'}>
          <Space>
            <Button
              type={'primary'} onClick={handleUpload}
              disabled={disabled || loading || fileList?.length === 0}
            >
              <Icon icon={'paper-plane'} />
              {'Submit'}
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )
}
