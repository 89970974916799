import {Descriptions} from 'antd'
import {LockerStabilityCounterDto} from 'axios/client'
import moment from 'moment'
import React from 'react'

import {DEFAULT_DATE_TIME_FORMAT} from '../../../config/client'

export interface StabilityInformationProps {
  data?: LockerStabilityCounterDto
  loading: boolean
}

export const StabilityInformation = ({data}: StabilityInformationProps): JSX.Element => (
  <Descriptions
    bordered={true} layout={'horizontal'} size={'small'}
    column={{xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1}}
  >
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last counter reset'} span={3}>
      {data?.date ? `${moment(data?.date).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
    </Descriptions.Item>
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Number of 4G connections'}>
      {data?.numberOfConnections ?? ''}
    </Descriptions.Item>
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Number of BT connections'}>
      {data?.bluetoothNumberOfConnections ?? ''}
    </Descriptions.Item>
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Offline in seconds'}>
      {data?.offlineInSeconds ?? ''}
    </Descriptions.Item>

    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Number of failed commands'}>
      {data?.numberOfFailedCommands ?? ''}
    </Descriptions.Item>
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Number of repeated same pins'}>
      {data?.repeatedSamePinsCount ?? ''}
    </Descriptions.Item>
    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Number of open errors'}>
      {data?.openErrorCount ?? ''}
    </Descriptions.Item>

    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last failed command'} span={3}>
      {data?.lastFailedCommand ? `${moment(data?.lastFailedCommand).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
    </Descriptions.Item>
  </Descriptions>
)
