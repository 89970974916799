import {Button, Card, Col, Modal, notification, PageHeader, Row, Space} from 'antd'
import {
  CompartmentWithWorkflowRequest,
  ConnectionStatus,
  DoorState,
  EventType,
  LockerDetailDto,
  LockerRequest,
  LockerState
} from 'axios/client'
import axios, {clientWithTimeout} from 'axios/instance'
import classNames from 'classnames'
import {Box} from 'components/box'
import {Icon} from 'components/icon'
import React, {useCallback, useEffect, useMemo, useReducer} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {throttle} from 'utils/promise'

import {CurrentInformation} from './currentInformation'
import {FanControlForm} from './fanControlForm'
import {LogRequestForm} from './logRequest'
import {OpenAllButton} from './openAllButton'
import {actions, initialState, reducer} from './reducer'
import {SetApnForm} from './setApn'
import {SetAutoHardResetForm} from './setAutoHardReset'
import {SetKeypadForm} from './setKeypad'
import {SetLcdTextForm} from './setLcdTextForm'
import {SetReportHardResetForm} from './setReportHardReset'
import {StabilityInformation} from './stabilityInformation'
import {UpgradeFirmwareForm} from './upgradeFirmwareForm'

const RETRY_ATTEMPTS = 20000
const RETRY_DELAY = 10000
const RETRY_THROTTLE = 10000

export const LockerDetail = React.memo((): JSX.Element => {
  // Use params for getting id from query string
  const {id} = useParams<{ id: string }>()

  // Use history for navigation
  const history = useHistory()

  // Use reducer
  const [{
    isConnected,
    refreshLoading,
    openLoading,
    data,
    lockerState,
    currentlyOpening
  }, dispatch] = useReducer(reducer, initialState)

  // Dispatched actions
  const setIsConnected = (isConnected: boolean): void => dispatch(actions.setIsConnected(isConnected))
  const setRefreshLoading = (loading: boolean): void => dispatch(actions.setRefreshLoading(loading))
  const setLockerState = (state: LockerState): void => dispatch(actions.setLockerState(state))
  const setOpenLoading = (loading: boolean): void => dispatch(actions.setOpenLoading(loading))
  const setCurrentlyOpening = (door: number | undefined): void => dispatch(actions.setCurrentlyOpening(door))
  const setFirmwareUpgradeLock = (lock: boolean): void => dispatch(actions.setFirmwareUpgradeLock(lock))
  const dataLoaded = (data: LockerDetailDto): void => dispatch(actions.dataLoaded(data))

  // Aliases
  const startRefreshLoading = useCallback((): void => setRefreshLoading(true), [])
  const stopRefreshLoading = useCallback((): void => setRefreshLoading(false), [])

  // Memorized data
  const request = useMemo(() => new LockerRequest({lockerId: id}), [id])

  // Handle back
  const handleBack = useCallback((): void => {
    history.push('/lockers')
  }, [history])

  // Load data
  const getLockerDetails = useCallback(() => {
    // Start loading and reset firmware upgrade status
    startRefreshLoading()
    // Check locker is connected
    axios.isLockerConnected(request).then(res => setIsConnected(res?.result?.toLowerCase() === 'ok'))
    // Load data
    axios
      .report(request)
      .then(response => {
        dataLoaded(response)
      })
      .catch(() => stopRefreshLoading())
  }, [request, startRefreshLoading, stopRefreshLoading])

  // Load data
  useEffect(() => {
    getLockerDetails()
  }, [getLockerDetails])

  // Check is connected with retry pattern
  const checkIsLockerConnected = useCallback(
    (numberOfRetry = RETRY_ATTEMPTS, ms = RETRY_DELAY): Promise<boolean> =>
      new Promise((resolve, reject) => {
        const fetchRetry = (attempt: number): Promise<void> =>
          axios
            .isLockerConnected(request)
            .then(res => {
              stopRefreshLoading()
              if (res?.result?.toLowerCase() === 'ok') {
                resolve(true)
              } else if (attempt === 1) throw reject('Error in getting http data')
              else {
                setTimeout(() => {
                  fetchRetry(attempt - 1).then()
                }, ms)
              }
            })
            .catch(err => {
              if (attempt === 1) reject(err)
              else {
                setTimeout(() => {
                  fetchRetry(attempt - 1).then()
                }, ms)
              }
            })
        return fetchRetry(numberOfRetry)
      }),
    [request, stopRefreshLoading]
  )

  // Reset locker
  const handleReset = useCallback(
    async skipConfirm => {
      // After confirm handler
      const handleConfirm = (): void => {
        startRefreshLoading()
        axios
          .resetLocker(request)
          .then(() => setIsConnected(false))
          .then(() =>
            throttle(() => {
              checkIsLockerConnected().then(getLockerDetails)
            }, RETRY_THROTTLE)
          )
      }
      // If skip confirm set then execute action without modal confirmation
      if (skipConfirm) {
        handleConfirm()
      } else {
        Modal.confirm({
          title: 'Locker reset',
          content: 'The locker will be unavailable after a while',
          onOk: handleConfirm,
        })
      }
    },
    [checkIsLockerConnected, getLockerDetails, request, startRefreshLoading]
  )

  // Reset handlers with predefined confirmation
  const handleResetWithConfirmation = useCallback(() => handleReset(false), [handleReset])
  const handleResetWithoutConfirmation = useCallback(() => handleReset(true), [handleReset])

  // Handle refresh
  const handleRefresh = useCallback((): void => getLockerDetails(), [getLockerDetails])

  // Disconnect locker
  const handleDisconnect = useCallback(() => {
    Modal.confirm({
      title: 'Disconnect locker',
      content: 'The locker will be unavailable after a while',
      onOk: () => {
        startRefreshLoading()
        axios
          .killAllLockerSessions(request)
          .then(() => setIsConnected(false))
          .then(() =>
            throttle(() => {
              checkIsLockerConnected().then(getLockerDetails)
            }, RETRY_THROTTLE)
          )
      },
    })
  }, [checkIsLockerConnected, getLockerDetails, request, startRefreshLoading])

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: 'Delete locker',
      content: 'The locker will be deleted. Are you sure?',
      onOk: () => {
        axios.deleteLocker(request)
          .then(() => setIsConnected(false))
          .catch(error =>
            notification.error({
              message: 'Error deleting locker',
              description: error?.detail ?? null,
              className: 'notify-colored error',
              duration: 4,
            })
          )
      },
    })
  }, [request])

  // Get handler by state
  const getHandlerForState = useCallback(
    (desiredState: LockerState) => {
      switch (desiredState) {
        case LockerState.Deactivated:
          return axios.deactivateLocker(request)
        case LockerState.Active:
          return axios.activateLocker(request)
        case LockerState.Production:
          return axios.productionActivateLocker(request)
        default:
          return undefined
      }
    },
    [request]
  )

  // Check locker state
  const changeLockerState = useCallback(
    (desiredState: LockerState) =>
      getHandlerForState(desiredState)
        ?.then(() => setLockerState(desiredState))
        .then(() =>
          notification.success({
            message: `Locker state has been set to "${desiredState}"`,
            className: 'notify-colored success',
            duration: 4,
          })
        )
        .catch(() =>
          notification.error({
            message: 'An error occurred while changing status',
            className: 'notify-colored error',
            duration: 4,
          })
        ),
    [getHandlerForState]
  )

  // Handle locker state change
  const handleLockerActive = useCallback(
    () =>
      Modal.confirm({
        title: 'Change locker state',
        content: 'The locker will be activated',
        onOk: () => changeLockerState(LockerState.Active),
      }),
    [changeLockerState]
  )
  const handleLockerProduction = useCallback(
    () =>
      Modal.confirm({
        title: 'Change locker state',
        content: 'The locker will be activated for production',
        onOk: () => changeLockerState(LockerState.Production),
      }),
    [changeLockerState]
  )
  const handleLockerDeactivated = useCallback(
    () =>
      Modal.confirm({
        title: 'Change locker state',
        content: 'The locker will be deactivated',
        onOk: () => changeLockerState(LockerState.Deactivated),
      }),
    [changeLockerState]
  )

  // Handle door click
  const handleDoorClicked = useCallback(
    (door: number, state: DoorState, type: EventType): void => {
      setCurrentlyOpening(door)
      axios
        .openCompartmentAdmin(
          new CompartmentWithWorkflowRequest({
            compartmentNumber: door,
            lockerId: id,
            workflowType: type,
          })
        )
        .then(res => {
          if (res.result?.toLowerCase() === 'ok') {
            notification.success({
              message: `Compartment number #${door} has been opened.`,
              className: 'notify-colored success',
              duration: 4,
            })
            getLockerDetails()
          } else {
            notification.warn({
              message: 'Locker is disconnected',
              className: 'notify-colored error',
              duration: 4,
            })
          }
        })
        .catch(error =>
          notification.error({
            message: 'Error during opening door',
            description: error?.detail ?? null,
            className: 'notify-colored error',
            duration: 4,
          })
        )
        .finally(() => setCurrentlyOpening(undefined))
    },
    [getLockerDetails, id]
  )

  // Handle confirm OK
  const handleOpenAll = useCallback(async () => {
    setOpenLoading(true)

    const timeout = (data?.doorCount ?? 10) * 3000
    clientWithTimeout(timeout)
      .openAllCompartments(request)
      .then(res => {
        if (res.result?.toLocaleLowerCase() === 'ok') {
          notification.success({
            message: 'All compartments have been opened',
            className: 'notify-colored success',
            duration: 4,
          })
          getLockerDetails()
        } else {
          notification.warn({
            message: 'Locker is disconnected',
            className: 'notify-colored error',
            duration: 4
          })
        }
      })
      .catch(error =>
        notification.error({
          message: 'Error on all compartments opening',
          description: error?.detail ?? null,
          className: 'notify-colored error',
          duration: 4,
        })
      )
      .finally(() => setOpenLoading(false))
  }, [data?.doorCount, getLockerDetails, request])

  //
  const handleNewRsaKeyClick = useCallback(() => {
    setRefreshLoading(true)
    axios.generateNewRsaKey(request).then(() => handleRefresh())
  }, [handleRefresh, request])

  const handleSwitchRsaKeyClick = useCallback(() => {
    // After confirm handler
    const handleConfirm = (): void => {
      axios
        .switchRsaKeys(request)
        .then(res => {
          if (res.result?.toLowerCase() === 'ok') {
            notification.success({
              message: 'RSA key has been switched.',
              className: 'notify-colored success',
              duration: 4,
            })
          }
        })
        .catch(error =>
          notification.error({
            message: 'Error during switching RSA key',
            description: error?.detail ?? null,
            className: 'notify-colored error',
            duration: 4,
          })
        )
    }

    Modal.confirm({
      title: 'Switch RSA Key',
      content: 'The RSA key will be switched',
      onOk: handleConfirm,
    })
  }, [request])

  // Render page header extra
  const pageHeaderExtra = useMemo(
    () => (
      <Space wrap={true}>
        <Button type={'primary'} onClick={handleRefresh} disabled={refreshLoading}>
          <Icon icon={'sync-alt'} />
          {'Refresh\r'}
        </Button>
        <Button
          type={'primary'} danger={true} disabled={refreshLoading || !isConnected}
          onClick={handleResetWithConfirmation}
        >
          <Icon icon={'redo-alt'} />
          {'Reset Locker'}
        </Button>
        <Button
          type={'primary'} danger={true} disabled={refreshLoading || !isConnected}
          onClick={handleDisconnect}
        >
          <Icon icon={'power-off'} />
          {'Disconnect form server'}
        </Button>
        <Button type={'primary'} danger={true} disabled={refreshLoading} onClick={handleDelete}>
          <Icon icon={'trash'} />
          {'Delete locker'}
        </Button>
      </Space>
    ),
    [handleRefresh, refreshLoading, isConnected, handleResetWithConfirmation, handleDisconnect, handleDelete]
  )

  // Render locker card extra
  const lockerCardExtra = useMemo(
    () => (
      <Space wrap={true}>
        <Button
          type={'primary'} disabled={lockerState === LockerState.Active || refreshLoading}
          onClick={handleLockerActive}
        >
          <Icon icon={'check'} />
          {'Activate Locker'}
        </Button>
        <Button
          type={'primary'} disabled={lockerState === LockerState.Production || refreshLoading}
          onClick={handleLockerProduction}
        >
          <Icon icon={'globe'} />
          {'Production Activate Locker'}
        </Button>
        <Button
          type={'primary'} disabled={lockerState === LockerState.Deactivated || refreshLoading}
          onClick={handleLockerDeactivated}
        >
          <Icon icon={'times'} />
          {'Deactivate Locker'}
        </Button>
        <OpenAllButton disabled={refreshLoading || !isConnected} onOpenAll={handleOpenAll} />
      </Space>
    ),
    [handleLockerActive, handleLockerDeactivated, handleLockerProduction, handleOpenAll, isConnected, lockerState, refreshLoading]
  )

  // Render current info card extra
  const currentInfoCardExtra = useMemo(() => {
    if (refreshLoading) {
      return (
        <div className={'connection-status-container '}>
          <div className={'connection-status-container margin'}>
            <strong className={'connection-status-icon working'}>{'4G'}</strong>
            <span className={'connection-status-text'}>{'Working'}</span>
          </div>
          <div className={'connection-status-container'}>
            <Icon icon={['fab', 'bluetooth-b']} className={'connection-status-icon working'} />
            <span className={'connection-status-text'}>{'Working'}</span>
          </div>
        </div>
      )
    }

    return (
      <div className={'connection-status-container'}>
        <div className={'connection-status-container margin'}>
          <strong
            className={classNames('connection-status-icon', {'is-connected': isConnected && data?.connectionStatus === ConnectionStatus.Connected})}
          >
            {'4G'}
          </strong>
          <span className={'connection-status-text'}>{isConnected && data?.connectionStatus === ConnectionStatus.Connected ? 'Connected' : 'Disconnected'}</span>
        </div>
        <div className={'connection-status-container'}>
          <Icon
            icon={['fab', 'bluetooth-b']}
            className={classNames('connection-status-icon', {'is-connected': isConnected && data?.bluetoothConnectionStatus === ConnectionStatus.Connected})}
          />
          <span
            className={'connection-status-text'}
          >
            {isConnected && data?.bluetoothConnectionStatus === ConnectionStatus.Connected ? 'Connected' : 'Disconnected'}
          </span>
        </div>
      </div>
    )
  }, [data?.bluetoothConnectionStatus, isConnected, refreshLoading])

  // Return JSX
  return (
    <Row>
      <Col span={24}>
        <PageHeader
          className={'site-page-header'} onBack={handleBack} title={'Locker'}
          extra={pageHeaderExtra}
        />
        <Card title={'Current information'} extra={currentInfoCardExtra}>
          <CurrentInformation
            data={data}
            onNewRsaKeyClick={handleNewRsaKeyClick}
            onSwitchRsaKeyClick={handleSwitchRsaKeyClick}
            loading={refreshLoading || openLoading || !isConnected}
            switchLoading={refreshLoading}
          />
        </Card>
        <Card title={'Stability counter'}>
          <StabilityInformation
            data={data?.stabilityCounter}
            loading={refreshLoading || openLoading || !isConnected}
          />
        </Card>
        <Row>
          <Col span={24}>
            <Card title={'Locker'} className={'box-container'} extra={lockerCardExtra}>
              <Box
                doorColumns={data?.doorColumns}
                compartments={data?.compartments}
                loading={refreshLoading || openLoading || !isConnected}
                onDoorClicked={handleDoorClicked}
                currentlyOpening={currentlyOpening}
              />
            </Card>
          </Col>
        </Row>
        <Row style={{display: 'flex'}}>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'LCD'} className={'box-container'} style={{height: '100%'}}>
              <SetLcdTextForm disabled={refreshLoading || !isConnected} lockerId={id} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Fan control'} className={'box-container'} style={{height: '100%'}}>
              <FanControlForm disabled={refreshLoading || !isConnected} lockerId={id} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Firmware'} className={'box-container'} style={{height: '100%'}}>
              <UpgradeFirmwareForm
                disabled={refreshLoading || !isConnected}
                lockerId={id}
                onLock={setFirmwareUpgradeLock}
                onReset={handleResetWithConfirmation}
                onRefresh={handleRefresh}
                onConnectionChange={setIsConnected}
                onSuccess={handleResetWithoutConfirmation}
              />
            </Card>
          </Col>
        </Row>

        <Row style={{display: 'flex', marginTop: 30}}>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Keypad'} className={'box-container'} style={{height: '100%'}}>
              <SetKeypadForm disabled={refreshLoading || !isConnected} lockerId={id} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Hard reset on Report'} className={'box-container'} style={{height: '100%'}}>
              <SetReportHardResetForm lockerId={id} disabled={refreshLoading || !isConnected} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Hard reset in 2 minutes'} className={'box-container'} style={{height: '100%'}}>
              <SetAutoHardResetForm lockerId={id} disabled={refreshLoading || !isConnected} />
            </Card>
          </Col>
        </Row>

        <Row style={{display: 'flex', marginTop: 30}}>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Provider parameters'} className={'box-container'} style={{height: '100%'}}>
              <SetApnForm lockerId={id} disabled={refreshLoading || !isConnected} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} xl={8}>
            <Card title={'Logs'} className={'box-container'} style={{height: '100%'}}>
              <LogRequestForm disabled={refreshLoading || !isConnected} lockerId={id} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
})
