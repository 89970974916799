import axios from 'axios/instance'
import {PublicClientApplication} from '@azure/msal-browser'

const azure = {
  MSAL_CONFIG: {
    auth: {
      clientId: '',
      authority: '',
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  },
  LOGIN_REQUEST: {
    scopes: [] as string[],
  },
  msalInstance: PublicClientApplication.prototype
}

let fetched = false

const load = async (): Promise<typeof azure> => {
  if (fetched) {
    return azure
  }
  try {
    const cfg = await axios.config()
    if (cfg?.scopes !== undefined) {
      azure.LOGIN_REQUEST.scopes = cfg.scopes
    }

    if (cfg?.clientId !== undefined) {
      azure.MSAL_CONFIG.auth.clientId = cfg.clientId
    }

    if (cfg?.authority !== undefined) {
      azure.MSAL_CONFIG.auth.authority = cfg.authority
    }
    azure.msalInstance = new PublicClientApplication({
      ...azure.MSAL_CONFIG
    })
    fetched = true
  } catch {
    // eslint-disable-next-line no-console
    console.log('Unable to get config')
  }
  return azure
}

export {load}
