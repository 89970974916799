import {Button, DatePicker, Form, Modal, notification, Space} from 'antd'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useState} from 'react'

import {ReadLogRequest} from '../../../axios/client'

// Form props
export interface LogRequestFormProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const LogRequestForm = ({lockerId, disabled}: LogRequestFormProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Handle form submit
  const handleFinish = ({date}: any): void => {
    if (date) {
      setLoading(true)

      axios
        .startLogDownload(new ReadLogRequest({lockerId, date}))
        .then(response => {
          if (response.logAvailable) {
            notification.success({message: 'Log will be sent to your email.'})
          } else {
            // eslint-disable-next-line quotes
            Modal.warn({title: 'Not found', content: 'Log doesn\'t exist for the selected date.'})
          }
        })
        .finally(() => {
          form.resetFields()
          setLoading(false)
        })
    }
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish}>
      <Form.Item
        name={'date'} required={true} label={'Date'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please select dates'}]}
      >
        <DatePicker disabled={disabled || loading} />
      </Form.Item>

      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
