import {LockerDetailDto, LockerState} from 'axios/client'
import {Actions, ActionWithPayload, createActionWithPayload} from 'utils/reducer'

/* State */
export interface State {
  data?: LockerDetailDto
  lockerState?: LockerState
  isConnected?: boolean
  isBluetoothConnected?: boolean
  currentlyOpening?: number
  refreshLoading: boolean
  openLoading: boolean
  firmwareUpgradeLock: boolean
}

/* Initial state */
export const initialState: State = {
  refreshLoading: true,
  openLoading: false,
  firmwareUpgradeLock: false,
}

/* Action names */
const actionNames = {
  SET_IS_CONNECTED: 'SET_IS_CONNECTED',
  SET_REFRESH_LOADING: 'SET_REFRESH_LOADING',
  SET_FIRMWARE_UPGRADE_LOCK: 'SET_FIRMWARE_UPGRADE_LOCK',
  SET_LOCKER_STATE: 'SET_LOCKER_STATE',
  SET_OPEN_LOADING: 'SET_OPEN_LOADING',
  SET_CURRENTLY_OPENING: 'SET_CURRENTLY_OPENING',
  DATA_LOADED: 'DATA_LOADED',
}

/* Actions */
export const actions = {
  setIsConnected: (isConnected: boolean): ActionWithPayload<boolean> => createActionWithPayload(actionNames.SET_IS_CONNECTED, isConnected),
  setRefreshLoading: (loading: boolean): ActionWithPayload<boolean> => createActionWithPayload(actionNames.SET_REFRESH_LOADING, loading),
  setFirmwareUpgradeLock: (lock: boolean): ActionWithPayload<boolean> => createActionWithPayload(actionNames.SET_FIRMWARE_UPGRADE_LOCK, lock),
  setLockerState: (state: LockerState): ActionWithPayload<LockerState> => createActionWithPayload(actionNames.SET_LOCKER_STATE, state),
  setOpenLoading: (loading: boolean): ActionWithPayload<boolean> => createActionWithPayload(actionNames.SET_OPEN_LOADING, loading),
  setCurrentlyOpening: (door: number | undefined): ActionWithPayload<number | undefined> => createActionWithPayload(actionNames.SET_CURRENTLY_OPENING, door),
  dataLoaded: (data: LockerDetailDto): ActionWithPayload<LockerDetailDto> => createActionWithPayload(actionNames.DATA_LOADED, data),
}

/* Reducer */
export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case actionNames.SET_IS_CONNECTED:
      return {...state, isConnected: action.payload}
    case actionNames.SET_REFRESH_LOADING:
      return {...state, refreshLoading: action.payload}
    case actionNames.SET_FIRMWARE_UPGRADE_LOCK:
      return {...state, firmwareUpgradeLock: action.payload}
    case actionNames.SET_LOCKER_STATE:
      return {...state, lockerState: action.payload}
    case actionNames.SET_OPEN_LOADING:
      return {...state, openLoading: action.payload}
    case actionNames.SET_CURRENTLY_OPENING:
      return {...state, currentlyOpening: action.payload}
    case actionNames.DATA_LOADED:
      return {...state, refreshLoading: false, data: action.payload, lockerState: action.payload.state}
    default:
      return state
  }
}
