import {Button, Col, Form, Modal, Row, Select, Space} from 'antd'
import {Icon} from 'components/icon'
import React, {useCallback, useMemo} from 'react'

export interface OpenAllButtonProps {
  disabled: boolean
  onOpenAll: () => void
}

export const OpenAllButton = React.memo(({disabled, onOpenAll}: OpenAllButtonProps): JSX.Element => {
  // Form reference
  const [form] = Form.useForm()

  // Handle form submit
  const handleFinish = useCallback((): void => onOpenAll(), [onOpenAll])

  // Modal content
  const modalContent = useMemo(
    (): JSX.Element => (
      <Row>
        <Col span={24}>
          <Space direction={'vertical'} size={20}>
            <span>{'Are you sure to open all compartments?'}</span>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name={'type'}
                label={
                  <Space direction={'horizontal'} size={4}>
                    <span>{'Please select'}</span>
                    <strong>{'"Yes, I am sure"'}</strong>
                    <span>{'to confirm'}</span>
                  </Space>
                }
                required={true}
                rules={[{required: true, message: 'Confirmation must be selected'}]}
              >
                <Select placeholder={'Select confirmation'} allowClear={true}>
                  <Select.Option key={'yes-i-am-sure'} value={'YES_I_AM_SURE'}>
                    {'Yes, I am sure'}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    ),
    [form, handleFinish]
  )

  // Handle door click
  const handleOpenAll = useCallback((): void => {
    form.resetFields()
    Modal.confirm({
      title: 'Open all doors',
      content: modalContent,
      onOk: () => form.validateFields().then(() => form.submit()),
    })
  }, [modalContent, form])

  // Return closed door button
  return (
    <Button type={'primary'} danger={true} onClick={handleOpenAll} disabled={disabled}>
      <Icon icon={'box-open'} />
      {'Open All Compartments'}
    </Button>
  )
})
