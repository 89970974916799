import {Button, Col, Descriptions, Form, Modal, Row, Select, Space, Tooltip} from 'antd'
import {LockerDetailDto} from 'axios/client'
import {DEFAULT_DATE_TIME_FORMAT} from 'config/client'
import moment from 'moment'
import React, {useCallback, useMemo} from 'react'

export interface CurrentInformationProps {
  data?: LockerDetailDto
  onNewRsaKeyClick: () => void
  onSwitchRsaKeyClick: () => void
  loading: boolean
  switchLoading: boolean
}

export const CurrentInformation = ({
                                     data,
                                     onNewRsaKeyClick,
                                     onSwitchRsaKeyClick,
                                     loading,
                                     switchLoading
                                   }: CurrentInformationProps): JSX.Element => {
  // Form reference
  const [form] = Form.useForm()

  // Handle form submit
  const handleFinish = useCallback((): void => onNewRsaKeyClick(), [onNewRsaKeyClick])

  // Modal content
  const modalContent = useMemo(
    (): JSX.Element => (
      <Row>
        <Col span={24}>
          <Space direction={'vertical'} size={20}>
            <span>{'Are you sure to generate new RSA key?'}</span>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name={'type'}
                label={
                  <Space direction={'horizontal'} size={4}>
                    <span>{'Please select'}</span>
                    <strong>{'"Yes, I am sure"'}</strong>
                    <span>{'to confirm'}</span>
                  </Space>
                }
                required={true}
                rules={[{required: true, message: 'Confirmation must be selected'}]}
              >
                <Select placeholder={'Select confirmation'} allowClear={true}>
                  <Select.Option key={'yes-i-am-sure'} value={'YES_I_AM_SURE'}>
                    {'Yes, I am sure'}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    ),
    [form, handleFinish]
  )

  // Handle new RSA key click
  const handleNewRsaKeyClick = useCallback((): void => {
    form.resetFields()
    Modal.confirm({
      title: 'Generate new RSA key',
      content: modalContent,
      onOk: () => form.validateFields().then(() => form.submit()),
    })
  }, [modalContent, form])

  return (
    <Descriptions
      bordered={true} layout={'horizontal'} size={'small'}
      column={{xxl: 4, xl: 4, lg: 2, md: 2, sm: 1, xs: 1}}
    >
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Id'} span={4}>
        {data?.uuid ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last report'} span={4}>
        {data?.report?.date ? `${moment(data?.report?.date).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Firmware'} span={4}>
        {data?.report?.firmwareVersion ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'RSA Key'} span={4}>
        <Space size={'small'}>
          {data?.rsaKeyCreated ? `${moment(data?.rsaKeyCreated).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
          {data && (
            <>
              <Button
                type={'primary'} onClick={handleNewRsaKeyClick} disabled={loading}
                className={'ml-5'}
              >
                {'Generate new RSA Key'}
              </Button>
              {data.canSwitchRsaKeys && (
                <Button
                  type={'primary'} onClick={onSwitchRsaKeyClick} disabled={switchLoading}
                  className={'ml-5'}
                >
                  {'Switch RSA Key'}
                </Button>
              )}
            </>
          )}
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Temperature'}>
        {data?.report?.temperature ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Signal'}>
        {data?.report?.signalStrength ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Charging Status'}>
        {data?.report?.chargingStatus ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Discharge Status'}>
        {data?.report?.dischargeStatus ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Battery'}>
        {data?.report?.batteryLevel ?? ''}
        {data?.report?.minBatteryLevelDate !== null && data?.report?.minBatteryLevelDate !== undefined && data?.report?.minBatteryLevelDate.getFullYear() > 2000 && (
          <>
            {' (min:'}
            <Tooltip
              placement={'left'}
              title={`${moment(data?.report?.minBatteryLevelDate).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC`}
            >
              {`${data.report.minBatteryLevel}`}
            </Tooltip>
            {')'}
          </>)
        }
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Battery Voltage'}>
        {data?.report?.batteryVoltage ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Battery Current'}>
        {data?.report?.batteryCurrent ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Battery Status'}>
        {data?.report?.batteryStatus ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Array Voltage'}>
        {data?.report?.arrayVoltage ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Load Voltage'}>
        {data?.report?.loadVoltage ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Array Current'}>
        {data?.report?.arrayCurrent ?? ''}
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Load Current'}>
        {data?.report?.loadCurrent ?? ''}
      </Descriptions.Item>
    </Descriptions>
  )
}
