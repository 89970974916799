import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {SignInCallback} from './containers/auth/callback/signIn'
import {LockerDetail} from './containers/locker/lockerDetail'
import {Lockers} from './containers/locker/lockers'
import {NotFound} from './containers/notFound'
import {useRoleChecker} from './hooks/useRoleChecker'
import {EmptyLayout} from './layouts/empty'
import {WebLayout} from './layouts/web'

export const Router = (): JSX.Element => {
  const {hasRole} = useRoleChecker()

  return (
    <Switch>
      <WebLayout
        path={'/lockers/:id/details'} component={LockerDetail} title={'Boxnow | Locker Detail'}
        exact={true} disabled={!(hasRole('Administrator') || hasRole('AdministratorLow') || hasRole('AdministratorMedium') || hasRole('AdministratorHigh'))}
      />
      <WebLayout path={'/lockers'} component={Lockers} title={'Boxnow | Lockers'} exact={true} />
      <EmptyLayout path={'/not-found'} component={NotFound} exact={true} title={'Boxnow | Not Found'} />
      <EmptyLayout path={'/auth/callback/signIn'} component={SignInCallback} exact={true} />

      <Route path={'/'}>
        <Redirect to={'/lockers'} />
      </Route>

      {/* Must be latest - default redirection to Not Found page */}
      <Route path={'*'}>
        <Redirect to={'/not-found'} />
      </Route>
    </Switch>
  )
}
