import instance, {AxiosRequestConfig} from 'axios'

import {SERVER_REQUEST_TIMEOUT, SERVER_URL} from '../config/server'
import {Client} from './client'

instance.interceptors.request.use(config => {
  const timestamp = new Date().toISOString()
  config.headers['request-timestamp'] = timestamp
  config.timeout = SERVER_REQUEST_TIMEOUT
  config.withCredentials = true

  return config
})

instance.interceptors.response.use(
  response => response,
  error => {
    // eslint-disable-next-line no-console
    console.error(error)

    return Promise.reject(error)
  }
)

const client = new Client(SERVER_URL, instance)

export const clientWithTimeout = (timeout: number): Client => {
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.timeout = timeout
    return config
  })

  return new Client(SERVER_URL, instance)
}

export default client
