import {usePolicy} from 'contexts/policy'
// Hook return type
import _size from 'lodash/size'
import _some from 'lodash/some'

export interface UseRoleChecker {
  // eslint-disable-next-line no-unused-vars
  hasRole: (role: string) => boolean
  // eslint-disable-next-line no-unused-vars
  hasAnyRoles: (roles: string[]) => boolean
  // eslint-disable-next-line no-unused-vars
  hasAllRoles: (roles: string[]) => boolean
}

export const useRoleChecker = (): UseRoleChecker => {
  // Hooks
  const {roles: userRoles} = usePolicy()

  // This method check if permission is exists and is granted
  const hasRole = (role: string): boolean => userRoles?.includes(role)

  // This method check if any permissions by name is granted
  const hasAnyRoles = (roles: string[]): boolean => {
    // Check if permissionNames arr not exists or is empty
    if (!roles || _size(roles) <= 0) return false
    // Loop over all permissionNames & check if any is granted
    return _some(roles, (role: string) => hasRole(role))
  }

  // This method check if all permissions by name is granted
  const hasAllRoles = (roles: string[]): boolean => {
    // Check if permissionNames arr not exists or is empty
    if (!roles || _size(roles) <= 0) return false
    // Loop over all permissionNames & check if any is not granted
    return !_some(roles, (role: string) => !hasRole(role))
  }

  return {hasRole, hasAnyRoles, hasAllRoles}
}
