import {Button, Space} from 'antd'
import {Icon} from 'components/icon'
import React, {useCallback, useEffect, useState} from 'react'
import axios from 'axios/instance'

import {load} from '../../../config/azure'

const fetchLogUrl = async () => {
  // Simulace asynchronního volání k získání čísla verze
  const info = await axios.info()
  return info.logUrl
}

export const Actions = React.memo((): JSX.Element => {
  const onLogoutClick = useCallback(async (): Promise<void> => {
    const {msalInstance} = await load()
    await msalInstance.logoutRedirect()
  }, [])

  const [logUrl, setVersion] = useState('')

  useEffect(() => {
    const getLogUrl = async () => {
      const logUrl = await fetchLogUrl()
      setVersion(logUrl ?? '')
    }
    getLogUrl().catch(error => {
      // Optional: You can handle errors here as well
      console.error('Failed to fetch log url', error)
    })
  }, []) // Empty dependency array means this effect runs once after initial render

  return (
    <div className={'actions'}>
      <Space style={{width: '100%'}}>
        {logUrl && (
          <Button href={logUrl} target={'_blank'} type={'primary'}>
            <Icon icon={'file-medical-alt'} />
            <span>{'Log'}</span>
          </Button>
        )}
        <Button type={'primary'} onClick={onLogoutClick}>
          <Icon icon={'power-off'} />
        </Button>
      </Space>
    </div>
  )
})
