import React from 'react'
import {useHistory} from 'react-router-dom'
import {useEffectOnce, useLocalStorage} from 'react-use'

import {AUTH_REDIRECT_KEY} from '../../../consts/localStorage'

export const SignInCallback = React.memo((): JSX.Element => {
  const [value] = useLocalStorage(AUTH_REDIRECT_KEY)

  const history = useHistory()

  useEffectOnce(() => {
    const redirectTo = ((value as Location).pathname ?? '/') as any
    history.push(redirectTo)
  })
  return <>{'REDIRECT'}</>
})
