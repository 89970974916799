import {Col, Row} from 'antd'
import React from 'react'

import {Actions} from './actions'
import {Logo} from './logo'

export const TopBar = React.memo(
  (): JSX.Element => (
    <div className={'top-bar'}>
      <Row>
        <Col span={12}>
          <Logo />
        </Col>
        <Col span={12}>
          <Actions />
        </Col>
      </Row>
    </div>
  )
)
