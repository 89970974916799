import {WarningOutlined} from '@ant-design/icons'
import {Button, Col, Descriptions, Form, Modal, Popover, Row, Select, Space} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {CompartmentDto, CompartmentWarningState, DoorState, EventType} from 'axios/client'
import classNames from 'classnames'
import moment from 'moment'
import React, {useCallback, useMemo} from 'react'

import {DEFAULT_DATE_TIME_FORMAT} from '../../config/client'

export interface CompartmentProps {
  loading: boolean
  value: number
  compartment?: CompartmentDto
  // eslint-disable-next-line no-unused-vars
  onDoorClicked: (value: number, state: DoorState, type: EventType) => void
}

export const Compartment = React.memo(({loading, value, compartment, onDoorClicked}: CompartmentProps): JSX.Element => {
  // Form reference
  const [form] = Form.useForm()

  const cssClassForState = (): string => {
    if (compartment?.state === DoorState.Closed) return 'door-closed'
    if (compartment?.state === DoorState.Open && compartment?.isLongOpen) return 'door-long-opened'
    if (compartment?.state === DoorState.Open && !compartment?.isLongOpen) return 'door-opened'
    return 'door-unknown'
  }

  // Handle form submit
  const handleFinish = useCallback(
    ({type}: Store): void => {
      onDoorClicked(value, compartment?.state ?? DoorState.Unknown, type)
    },
    [onDoorClicked, compartment?.state, value]
  )

  // Modal content
  const modalContent = useMemo(
    (): JSX.Element => (
      <Row>
        <Col span={24}>
          <Space direction={'vertical'} size={20}>
            <span>{`If you are sure to open door #${value}, please select the right workflow type and confirm it.`}</span>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name={'type'} label={<strong>{'Workflow type'}</strong>} required={true}
                rules={[{required: true, message: 'Workflow type must be selected'}]}
              >
                <Select placeholder={'Select workflow type'} allowClear={true}>
                  {Object.keys(EventType).map(key => (
                    <Select.Option key={key} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    ),
    [form, handleFinish, value]
  )

  // Handle door click
  const onDoorClick = useCallback((): void => {
    form.resetFields()
    Modal.confirm({
      title: (
        <Space size={4} direction={'horizontal'}>
          <span>{'Open door'}</span>
          <strong>{`#${value}`}</strong>
        </Space>
      ),
      content: modalContent,
      onOk: () => form.validateFields().then(() => form.submit()),
    })
  }, [form, value, modalContent])

  const popoverContent = useMemo(
    () => (
      <Descriptions style={{maxWidth: 300}} size={'small'} column={{xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1}}>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Compartment Number'}>
          {compartment?.compartmentNumber ?? ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'State'}>
          {compartment?.state ?? ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Warning'}>
          {compartment?.warning ?? ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Is Long Open'}>
          {compartment?.isLongOpen ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last Open Command'}>
          {compartment?.lastOpenCommand ? `${moment(compartment?.lastOpenCommand).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last State Change'}>
          {compartment?.lastStateChangeDate ? `${moment(compartment?.lastStateChangeDate).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Last Open Error'}>
          {compartment?.lastOpenErrorDate ? `${moment(compartment?.lastOpenErrorDate).utc().format(DEFAULT_DATE_TIME_FORMAT)} UTC` : ''}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={'Open Error Counter'}>
          {compartment?.openErrorCount ?? 0}
        </Descriptions.Item>
      </Descriptions>
    ),
    [
      compartment?.compartmentNumber,
      compartment?.isLongOpen,
      compartment?.lastOpenCommand,
      compartment?.lastOpenErrorDate,
      compartment?.lastStateChangeDate,
      compartment?.openErrorCount,
      compartment?.state,
      compartment?.warning,
    ]
  )

  const render = (): JSX.Element => {
    // If loading then show placeholder
    if (loading) {
      return (
        <Button className={'compartment placeholder'} disabled={true}>
          <span
            className={'with-icon'}
            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
          >
            {compartment?.warning !== CompartmentWarningState.None ? <WarningOutlined /> : <span />}
            {value}
            <span />
          </span>
        </Button>
      )
    }

    // Return door button
    return (
      <Button className={classNames('compartment', cssClassForState())} onClick={onDoorClick}>
        <span className={'with-icon'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {compartment?.warning !== CompartmentWarningState.None ? <WarningOutlined /> : <span />}
          {value}
          <span />
        </span>
      </Button>
    )
  }

  return (
    <Popover content={popoverContent} placement={'right'}>
      {render()}
    </Popover>
  )
})
