import {Layout} from 'antd'
import {Icon} from 'components/icon'
import React, { useEffect, useState } from 'react'
import axios from 'axios/instance'

const fetchVersion = async () => {
  // Simulace asynchronního volání k získání čísla verze
  const info = await axios.info()
  return info.version
}

export const Footer = React.memo(
  (): JSX.Element => {
    const [version, setVersion] = useState('')

    useEffect(() => {
      const getVersion = async () => {
        const version = await fetchVersion()
        setVersion(version ?? '??')
      }
      getVersion().catch(error => {
        // Optional: You can handle errors here as well
        console.error('Failed to fetch version', error)
      })
    }, []) // Empty dependency array means this effect runs once after initial render

    return (
      <Layout.Footer className={'footer'}>
        <span className={'powered-by'}>
          {'made with'}
          <Icon icon={'heart'} />
          {'by '}
          <strong>{'LinkSoft Technologies, a.s.'}</strong>
          <span className={'separator'}>{'|'}</span>
        </span>
        <span className={'version'}>
          {'version '}
          <strong>{version || 'loading...'}</strong>
        </span>
      </Layout.Footer>
    )
  }
)
