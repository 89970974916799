import React, {useContext, useState} from 'react'
import {useEffectOnce} from 'react-use'

import axios from '../axios/instance'

export interface PolicyDefinition {
  roles: string[]
  // eslint-disable-next-line no-unused-vars
  setRoles?: (roles: string[]) => void
}

export const PolicyContext = React.createContext<PolicyDefinition>({roles: []})

export const PolicyProvider = ({children}: React.PropsWithChildren<any>): JSX.Element => {
  const [roles, setRoles] = useState<string[] | undefined>()

  useEffectOnce(() => {
    axios.user().then(values => setRoles(values.roles ?? []))
  })

  return roles ? <PolicyContext.Provider value={{roles, setRoles}}>{children}</PolicyContext.Provider> : <></>
}

export const usePolicy = (): PolicyDefinition => useContext<PolicyDefinition>(PolicyContext)
