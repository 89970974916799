import {Button, Col, Row, Space} from 'antd'
import {CompartmentDto, DoorState, EventType} from 'axios/client'
import _max from 'lodash/max'
import _size from 'lodash/size'
import _sum from 'lodash/sum'
import _take from 'lodash/take'
import React, {useCallback} from 'react'

import {Compartment} from './compartment'

export interface BoxProps {
  loading: boolean
  currentlyOpening?: number
  doorColumns?: number[]
  compartments?: CompartmentDto[]
  // eslint-disable-next-line no-unused-vars
  onDoorClicked: (value: number, state: DoorState, type: EventType) => void
}

export const Box = ({loading, doorColumns, compartments, onDoorClicked, currentlyOpening}: BoxProps): JSX.Element => {
  // Get door state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCompartment = (door: number): CompartmentDto | undefined => compartments?.find(item => item.compartmentNumber === door)

  // Render matrix row
  const renderMatrixRow = useCallback(
    (x: number, y: number, currentRow: number, columns: number[]): JSX.Element => {
      const col = []
      for (let i = 0; i < x; i += 1) {
        if (currentRow >= columns[i]) {
          col.push(
            <Button key={`placeholder-${x}${y}`} className={'compartment placeholder'} disabled={true}>
              {'x'}
            </Button>
          )
        } else {
          const overflow = i === 0 ? 0 : _sum(_take(columns, i))
          const door = overflow + currentRow + 1
          col.push(<Compartment
            key={door.toString()} value={door}
            compartment={compartments && getCompartment(door)}
            onDoorClicked={onDoorClicked}
            loading={loading || currentlyOpening === door}
                   />)
        }
      }
      return (
        <div className={'box-row'} key={currentRow.toString()}>
          {col}
        </div>
      )
    },
    [compartments, getCompartment, onDoorClicked, loading, currentlyOpening]
  )

  // Render compartment matrix
  const renderMatrix = useCallback(
    (x: number, y: number, columns: number[]): JSX.Element[] => {
      const rows = []
      for (let row = 0; row < y; row += 1) rows.push(renderMatrixRow(x, y, row, columns))
      return rows
    },
    [renderMatrixRow]
  )

  // Render JSX
  return doorColumns ? (
    <Row>
      <Space direction={'vertical'} size={25}>
        <Col span={24}>{renderMatrix(_size(doorColumns), _max(doorColumns) ?? 0, doorColumns)}</Col>
      </Space>
    </Row>
  ) : (
    <></>
  )
}
