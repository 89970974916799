import 'styles/index.less'

import {EventType} from '@azure/msal-browser'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import ReactDOM from 'react-dom'

import {App} from './app'
import {load} from './config/azure'
import reportWebVitals from './reportWebVitals'

// Register all font awesome icons
library.add(fab, fas, far)

const render = async (): Promise<void> => {
  const azure = await load()
  const {msalInstance} = azure
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
      const account = (event.payload as any).account ?? null
      msalInstance.setActiveAccount(account)
    }
  })

  ReactDOM.render(
    <React.StrictMode>
      <App azure={azure} />
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

render()
