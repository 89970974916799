import {InteractionType, NavigationClient, NavigationOptions} from '@azure/msal-browser'
import {MsalAuthenticationTemplate, MsalProvider} from '@azure/msal-react'
import {ConfigProvider} from 'antd'
import React from 'react'
import {BrowserRouter, useHistory} from 'react-router-dom'
import {useLocalStorage} from 'react-use'

import RequestInterceptor from './axios/requestInterceptor'
import {AUTH_REDIRECT_KEY} from './consts/localStorage'
import {PolicyProvider} from './contexts/policy'
import {ErrorBoundary} from './errorBoundary'
import {Router} from './router'

class CustomNavigationClient extends NavigationClient {
  private history: any

  constructor(history: any) {
    super()
    this.history = history // Passed in from useHistory hook provided by react-router-dom;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    if (url === null || url === undefined || this.history === null || this.history === undefined) return false
    const relativePath = url.replace(window.location.origin, '')
    if (options.noHistory) {
      this.history.replace(relativePath)
    } else {
      this.history.push(relativePath)
    }
    return false
  }
}

export const App = ({azure}: any): JSX.Element => {
  useLocalStorage(AUTH_REDIRECT_KEY, window.location)

  const history = useHistory()
  const navigationClient = new CustomNavigationClient(history)
  const {msalInstance, LOGIN_REQUEST} = azure
  azure.msalInstance.setNavigationClient(navigationClient)

  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{
            ...LOGIN_REQUEST,
            redirectUri: `${window.location.origin}/auth/callback/signIn`,
            state: `&redirect_to=${window.location.href}`,
          }}
        >
          <ConfigProvider>
            <RequestInterceptor>
              <PolicyProvider>
                <ErrorBoundary>
                  <Router />
                </ErrorBoundary>
              </PolicyProvider>
            </RequestInterceptor>
          </ConfigProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </BrowserRouter>
  )
}
