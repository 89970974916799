import {Layout} from 'antd'
import React from 'react'
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router-dom'
import {useEffectOnce, useLocalStorage, useTitle} from 'react-use'

import {AUTH_REDIRECT_KEY} from '../../consts/localStorage'
import {Footer} from './footer'
import {Header} from './header'

export interface WebLayoutProps extends RouteProps {
  authorized?: boolean
  component: any
  disabled?: boolean
  permissions?: string[]
  title?: string
}

export const WebLayout = React.memo(({disabled, component: Component, title, ...rest}: WebLayoutProps): JSX.Element => {
  const [, , remove] = useLocalStorage(AUTH_REDIRECT_KEY)

  useEffectOnce(() => remove())

  // Set page title
  useTitle(title ?? '')

  // Render layout
  const renderLayout = (props: RouteComponentProps<any>): JSX.Element => (
    <Layout key={'web-layout'} className={'layout web'}>
      <Header />
      <Layout.Content className={'content'}>
        <Component {...props} />
      </Layout.Content>
      <Footer />
    </Layout>
  )

  return !disabled ? <Route {...rest} render={renderLayout} /> : <Redirect to={'/exception/404'} />
})
