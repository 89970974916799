import {Layout} from 'antd'
import React from 'react'

import {TopBar} from './topBar'

export const Header = React.memo(
  (): JSX.Element => (
    <Layout.Header className={'header'}>
      <TopBar />
    </Layout.Header>
  )
)
