import {ConnectionStatus, LockerState} from 'axios/client'

export class LockerStatistics {
  all: number

  new: number

  active: number

  production: number

  deactivated: number

  unknown: number

  connected: number

  bluetoothConnected: number

  disconnected: number

  bluetoothDisconnected: number

  constructor() {
    this.all = 0
    this.new = 0
    this.active = 0
    this.production = 0
    this.deactivated = 0
    this.unknown = 0
    this.connected = 0
    this.bluetoothConnected = 0
    this.disconnected = 0
    this.bluetoothDisconnected = 0
  }

  incrementByState = (state?: LockerState, connectionStatus?: ConnectionStatus, bluetoothConnectionStatus?: ConnectionStatus): void => {
    this.all += 1

    switch (connectionStatus) {
      case ConnectionStatus.Connected:
        this.connected += 1
        break
      case ConnectionStatus.Disconnected:
        this.disconnected += 1
        break
      default:
        break
    }

    switch (bluetoothConnectionStatus) {
      case ConnectionStatus.Connected:
        this.bluetoothConnected += 1
        break
      case ConnectionStatus.Disconnected:
        this.bluetoothDisconnected += 1
        break
      default:
        break
    }

    switch (state) {
      case LockerState.New:
        this.new += 1
        break
      case LockerState.Active:
        this.active += 1
        break
      case LockerState.Production:
        this.production += 1
        break
      case LockerState.Deactivated:
        this.deactivated += 1
        break
      default:
        this.unknown += 1
        break
    }
  }
}
