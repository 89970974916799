import {SearchOutlined} from '@ant-design/icons'
import {Badge, Button, Card, Col, Input, Modal, Row, Space, Table, Tag, Tooltip} from 'antd'
import {ColumnType, FilterDropdownProps} from 'antd/lib/table/interface'
import {LockerListDto, LockerRequest} from 'axios/client'
import axios from 'axios/instance'
import classNames from 'classnames'
import {Icon} from 'components/icon'
import {useRoleChecker} from 'hooks/useRoleChecker'
import React, {ChangeEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react'
import Highlighter from 'react-highlight-words'
import {Link, useHistory} from 'react-router-dom'

import {ERRORS} from '../../../consts/errors'
import {Locker} from './locker'
import {actions, initialState, reducer} from './reducer'
import {SearchState} from './searchState'
import {RecordType} from './types'

export const Lockers = React.memo((): JSX.Element => {
  // Use history for navigation
  const history = useHistory()
  const {hasRole} = useRoleChecker()
  const isAdmin = hasRole('Administrator') || hasRole('AdministratorLow') || hasRole('AdministratorMedium') || hasRole('AdministratorHigh')
  const isOperator = hasRole('Operator')

  const [{data, loading, statistics, searchState}, dispatch] = useReducer(reducer, initialState)

  // Dispatched actions
  const setLoading = (loading: boolean): void => dispatch(actions.setLoading(loading))
  const setSearchState = (state: SearchState): void => dispatch(actions.setSearchState(state))
  const dataLoaded = (data: LockerListDto[]): void => dispatch(actions.dataLoaded(data))
  const updateRow = (row: LockerListDto): void => dispatch(actions.updateRow(row))

  // Use ref for search input
  const searchInput = useRef<Input>(null)

  // Load data
  const loadData = useCallback((): void => {
    setLoading(true)
    axios.getLockers().then(res => dataLoaded(res))
  }, [])

  // Load data when header is changed
  useEffect(loadData, [loadData])

  // handle Search
  const handleSearch = useCallback((selectedKeys: React.Key[], confirm: () => void, dataIndex: string): void => {
    confirm()
    setSearchState({
      text: selectedKeys[0].toString(),
      column: dataIndex,
    })
  }, [])

  // Handle reset
  const handleReset = useCallback((clearFilters: Function | undefined): void => {
    clearFilters && clearFilters()
    setSearchState({text: ''})
  }, [])

  // Column search props
  const getColumnSearchProps = useCallback(
    (dataIndex: string): ColumnType<Locker> => ({
      filterDropdown: ({
                         setSelectedKeys,
                         selectedKeys,
                         confirm,
                         clearFilters
                       }: FilterDropdownProps): React.ReactNode => {
        // local handlers
        const handleChangeLocally = (e: ChangeEvent<HTMLInputElement>): void => setSelectedKeys(e.target.value ? [e.target.value] : [])
        const handleSearchLocally = (): void => handleSearch(selectedKeys, confirm, dataIndex)
        const handleResetLocally = (): void => handleReset(clearFilters)
        const handleFilterLocally = (): void => {
          confirm({closeDropdown: false})
          setSearchState({
            text: selectedKeys[0].toString(),
            column: dataIndex,
          })
        }
        // Filter input
        return (
          <div style={{padding: 8}}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={handleChangeLocally}
              onPressEnter={handleSearchLocally}
              style={{marginBottom: 8, display: 'block'}}
            />
            <Space>
              <Button
                type={'primary'} onClick={handleSearchLocally} icon={<SearchOutlined />}
                size={'small'} style={{width: 90}}
              >
                {'Search\r'}
              </Button>
              <Button onClick={handleResetLocally} size={'small'} style={{width: 90}}>
                {'Reset\r'}
              </Button>
              <Button type={'link'} size={'small'} onClick={handleFilterLocally}>
                {'Filter\r'}
              </Button>
            </Space>
          </div>
        )
      },
      filterIcon: (filtered: boolean): JSX.Element => <SearchOutlined
        style={{color: filtered ? '#1890ff' : undefined}}
                                                      />,
      onFilter: (value: string | number | boolean, record: RecordType): boolean => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase()) : ''),
      onFilterDropdownVisibleChange: (visible: boolean): void => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.current && searchInput.current.select(), 100)
        }
      },
      // eslint-disable-next-line no-confusing-arrow
      render: (text: string): JSX.Element | string =>
        searchState?.column === dataIndex ? (
          <Highlighter
            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
            searchWords={[searchState.text ?? '']} autoEscape={true}
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }),
    [handleReset, handleSearch, searchState?.column, searchState?.text]
  )

  const handleResetCounters = useCallback(() => {
    // After confirm handler
    const handleConfirm = (): void => {
      setLoading(true)
      axios.resetLockerCounters().then(loadData)
    }

    Modal.confirm({
      title: 'Counters Reset',
      content: 'The locker counters will be reset',
      onOk: handleConfirm,
    })
  }, [loadData])

  // Render connection status
  const renderConnectionStatus4G = (text: string, record: Locker): JSX.Element => {
    const isConnected = record.connectionStatus === 'Connected'
    return (
      <div className={'connection-status-container on-grid'}>
        <span className={classNames('connection-status-icon', {'is-connected': isConnected})}>
          <strong>{'4G'}</strong>
        </span>
      </div>
    )
  }

  const renderConnectionStatusBT = (text: string, record: Locker): JSX.Element => {
    const isConnected = record.bluetoothConnectionStatus === 'Connected'
    return (
      <div className={'connection-status-container on-grid'}>
        <Icon
          icon={['fab', 'bluetooth-b']}
          className={classNames('connection-status-icon', {'is-connected': isConnected})}
        />
      </div>
    )
  }

  const renderLastConnected = (text: string): JSX.Element => <>{`${text} UTC`}</>
  const renderDate = (text: string): JSX.Element => <>{`${text} UTC`}</>

  const renderErrors = (text: string): JSX.Element => {
    const split = text.split(',')

    const tooltip = (
      <ul>
        {split.map(item => (
          <li key={item.trim()}>
            <strong>{item.trim()}</strong>
            <span>{' - '}</span>
            <span>{(ERRORS as any)[`${item.trim()}`]}</span>
          </li>
        ))}
      </ul>
    )

    return <Tooltip placement={'left'} title={tooltip}>{`${text}`}</Tooltip>
  }

  const renderMinBatteryDate = (value: number, record: Locker): JSX.Element => {
    if (record?.minBatteryDateExists) {
      return <Tooltip placement={'left'} title={record.minBatteryDateFormatted}>{`${record.minBattery}`}</Tooltip>
    }
    return <></>
  }

  const renderLongOpenedDoors = (text: string, record: Locker): JSX.Element => {
    const result: JSX.Element[] = []
    if (record?.longOpenedDoors) {
      const longOpenedDoorsCount = record.longOpenedDoors.length
      record.longOpenedDoors
        ?.sort((a, b) => a - b)
        .forEach((door, index) => {
          const className = record.waterWarningDoors?.includes(door) ? 'water-warning' : ''
          const text = longOpenedDoorsCount - 1 > index ? `${door} ,` : door
          result.push(<span className={className}>{text}</span>)
        })
      return <div className={'d-flex'}>{result}</div>
    }
    return <></>
  }

  const refreshRow = useCallback((lockerId: string): void => {
    setLoading(true)
    axios
      .report({lockerId} as LockerRequest)
      .then(response => updateRow(response))
      .finally(() => setLoading(false))
  }, [])

  // Show action buttons
  const showActionsButtons = (record: Locker): JSX.Element => (
    <Space size={'middle'}>
      <Link to={`/lockers/${record.uuid}/details`}>{'Detail'}</Link>
    </Space>
  )

  // On row props - handle row double click
  const showRefreshButton = useCallback(
    (record: Locker): JSX.Element => (
      <Button onClick={(): void => refreshRow(record.uuid)} type={'primary'} size={'small'}>
        <Icon icon={'sync-alt'} />
        {'Refresh'}
      </Button>
    ),
    [refreshRow]
  )

  const onRowProps = useCallback(
    (record: Locker) => ({
      onDoubleClick: (): void => history.push(`/lockers/${record.uuid}/details`),
    }),
    [history]
  )

  // Sorters
  const sorters = useMemo(
    () => ({
      connectionStatus: (a: Locker, b: Locker): number => a.connectionStatus.length - b.connectionStatus.length,
      bluetoothConnectionStatus: (a: Locker, b: Locker): number => a.bluetoothConnectionStatus.length - b.bluetoothConnectionStatus.length,
      uuid: (a: Locker, b: Locker): number => `${a.uuid}`.localeCompare(b.uuid),
      state: (a: Locker, b: Locker): number => a.state.length - b.state.length,
      temperature: (a: Locker, b: Locker): number => a.temperature - b.temperature,
      date: (a: Locker, b: Locker): number => a.dateFormattedUnix - b.dateFormattedUnix,
      battery: (a: Locker, b: Locker): number => a.battery - b.battery,
      minBattery: (a: Locker, b: Locker): number => a.minBattery - b.minBattery,
      arrayVoltage: (a: Locker, b: Locker): number => a.arrayVoltage - b.arrayVoltage,
      loadCurrent: (a: Locker, b: Locker): number => a.loadCurrent - b.loadCurrent,
      batteryCurrent: (a: Locker, b: Locker): number => a.batteryCurrent - b.batteryCurrent,
      signal: (a: Locker, b: Locker): number => a.signal - b.signal,
      doorCount: (a: Locker, b: Locker): number => a.doorCount - b.doorCount,
      offlineInSeconds: (a: Locker, b: Locker): number => a.offlineInSeconds - b.offlineInSeconds,
      numberOfConnections: (a: Locker, b: Locker): number => a.numberOfConnections - b.numberOfConnections,
      numberOfFailedCommands: (a: Locker, b: Locker): number => a.numberOfFailedCommands - b.numberOfFailedCommands,
      repeatedSamePinsCount: (a: Locker, b: Locker): number => a.repeatedSamePinsCount - b.repeatedSamePinsCount,
      openErrorCount: (a: Locker, b: Locker): number => a.openErrorCount - b.openErrorCount,
      longOpenedDoors: (a: Locker, b: Locker): number => a.longOpenedDoorsCount - b.longOpenedDoorsCount,
      lastConnected: (a: Locker, b: Locker): number => a.lastConnectedFormattedUnix - b.lastConnectedFormattedUnix,
      firmware: (a: Locker, b: Locker): number => `${a.firmwareVersion}`.localeCompare(b.firmwareVersion),
      errorCodes: (a: Locker, b: Locker): number => a.errorCodes?.join().localeCompare(b.errorCodes?.join()),
      score: (a: Locker, b: Locker): number => a.score - b.score,
    }),
    []
  )

  // Table columns
  const columnDefinitions = useMemo(
    () => [
      {
        title: '',
        key: 'refresh',
        render: (text: string, record: Locker): JSX.Element => showRefreshButton(record),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text: string, record: Locker): JSX.Element => showActionsButtons(record),
      },
      {
        title: '4G',
        dataIndex: 'connectionStatus',
        key: 'connectionStatus',
        sorter: sorters.connectionStatus,
        filters: [
          {text: 'Connected', value: 'Connected'},
          {text: 'Disconnected', value: 'Disconnected'},
        ],
        onFilter: (value: string | number | boolean, record: Locker): boolean => record?.connectionStatus?.includes(value.toString()) ?? false,
        render: renderConnectionStatus4G,
      },
      {
        title: 'BT',
        dataIndex: 'bluetoothConnectionStatus',
        key: 'bluetoothConnectionStatus',
        sorter: sorters.bluetoothConnectionStatus,
        filters: [
          {text: 'Connected', value: 'Connected'},
          {text: 'Disconnected', value: 'Disconnected'},
        ],
        onFilter: (value: string | number | boolean, record: Locker): boolean => record?.bluetoothConnectionStatus?.includes(value.toString()) ?? false,
        render: renderConnectionStatusBT,
      },
      {
        title: 'ID',
        dataIndex: 'uuid',
        key: 'uuid',
        sorter: sorters.uuid,
        ...getColumnSearchProps('uuid'),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: sorters.state,
        filters: [
          {text: 'New', value: 'New'},
          {text: 'Deactivated', value: 'Deactivated'},
          {text: 'Active', value: 'Active'},
          {text: 'Production', value: 'Production'},
        ],
        onFilter: (value: string | number | boolean, record: Locker): boolean => record?.state?.includes(value.toString()) ?? false,
      },
      {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        sorter: sorters.score,
        ...getColumnSearchProps('score'),
      },
      {
        title: 'Temperature',
        dataIndex: 'temperature',
        key: 'temperature',
        sorter: sorters.temperature,
        ...getColumnSearchProps('temperature'),
      },
      {
        title: 'Battery',
        dataIndex: 'battery',
        key: 'battery',
        sorter: sorters.battery,
        ...getColumnSearchProps('battery'),
      },
      {
        title: 'Min Battery',
        dataIndex: 'minBattery',
        key: 'minBattery',
        sorter: sorters.minBattery,
        ...getColumnSearchProps('minBattery'),
        render: renderMinBatteryDate,
      },
      {
        title: 'Signal',
        dataIndex: 'signal',
        key: 'signal',
        sorter: sorters.signal,
        ...getColumnSearchProps('signal'),
      },
      {
        title: 'Array Voltage',
        dataIndex: 'arrayVoltage',
        key: 'arrayVoltage',
        sorter: sorters.arrayVoltage,
        ...getColumnSearchProps('arrayVoltage'),
      },
      {
        title: 'Load Current',
        dataIndex: 'loadCurrent',
        key: 'loadCurrent',
        sorter: sorters.loadCurrent,
        ...getColumnSearchProps('loadCurrent'),
      },
      {
        title: 'Battery Current',
        dataIndex: 'batteryCurrent',
        key: 'batteryCurrent',
        sorter: sorters.batteryCurrent,
        ...getColumnSearchProps('batteryCurrent'),
      },
      {
        title: 'Report Date',
        key: 'date',
        dataIndex: 'dateFormatted',
        sorter: sorters.date,
        ...getColumnSearchProps('date'),
        render: renderDate,
      },
      {
        title: 'Door Count',
        dataIndex: 'doorCount',
        key: 'doorCount',
        sorter: sorters.doorCount,
        ...getColumnSearchProps('doorCount'),
      },
      {
        title: 'Long Opened Doors',
        dataIndex: 'longOpenedDoorsString',
        key: 'longOpenedDoors',
        sorter: sorters.longOpenedDoors,
        ...getColumnSearchProps('longOpenedDoors'),
        render: renderLongOpenedDoors,
      },
      {
        title: 'Offline in seconds',
        dataIndex: 'offlineInSeconds',
        key: 'offlineInSeconds',
        sorter: sorters.offlineInSeconds,
        ...getColumnSearchProps('offlineInSeconds'),
      },
      {
        title: 'Number of 4G connections',
        dataIndex: 'numberOfConnections',
        key: 'numberOfConnections',
        sorter: sorters.numberOfConnections,
        ...getColumnSearchProps('numberOfConnections'),
      },
      {
        title: 'Number of failed commands',
        dataIndex: 'numberOfFailedCommands',
        key: 'numberOfFailedCommands',
        sorter: sorters.numberOfFailedCommands,
        ...getColumnSearchProps('numberOfFailedCommands'),
      },
      {
        title: 'Number of repeated same pins',
        dataIndex: 'repeatedSamePinsCount',
        key: 'repeatedSamePinsCount',
        sorter: sorters.repeatedSamePinsCount,
        ...getColumnSearchProps('repeatedSamePinsCount'),
      },
      {
        title: 'Number of open errors',
        dataIndex: 'openErrorCount',
        key: 'openErrorCount',
        sorter: sorters.openErrorCount,
        ...getColumnSearchProps('openErrorCount'),
      },
      {
        title: 'Last Connection',
        key: 'lastConnected',
        dataIndex: 'lastConnectedFormatted',
        sorter: sorters.lastConnected,
        ...getColumnSearchProps('lastConnected'),
        render: renderLastConnected,
      },
      {
        title: 'Firmware',
        key: 'firmwareVersion',
        dataIndex: 'firmwareVersion',
        sorter: sorters.firmware,
        ...getColumnSearchProps('firmwareVersion'),
      },
      {
        title: 'Errors',
        key: 'errorCodes',
        dataIndex: 'errorCodesString',
        sorter: sorters.errorCodes,
        ...getColumnSearchProps('errorCodesString'),
        render: renderErrors,
      },
    ],
    [
      sorters.connectionStatus,
      sorters.bluetoothConnectionStatus,
      sorters.uuid,
      sorters.state,
      sorters.temperature,
      sorters.date,
      sorters.score,
      sorters.battery,
      sorters.minBattery,
      sorters.signal,
      sorters.arrayVoltage,
      sorters.loadCurrent,
      sorters.batteryCurrent,
      sorters.doorCount,
      sorters.longOpenedDoors,
      sorters.offlineInSeconds,
      sorters.numberOfConnections,
      sorters.numberOfFailedCommands,
      sorters.repeatedSamePinsCount,
      sorters.openErrorCount,
      sorters.lastConnected,
      sorters.firmware,
      sorters.errorCodes,
      getColumnSearchProps,
      showRefreshButton,
    ]
  )

  const columns = useMemo(() => (!isAdmin ? columnDefinitions.filter(col => col.key !== 'actions') : columnDefinitions), [columnDefinitions, isAdmin])

  const refreshButton = useMemo(
    (): JSX.Element => (
      <Space direction={'horizontal'} size={20}>
        <Space direction={'horizontal'} wrap={true}>
          <div className={'stats'}>
            <Tag color={'default'}>
              <Icon icon={'list'} />
              <strong>{`All lockers: ${statistics.all}`}</strong>
            </Tag>
            <Tag color={'processing'}>
              <Icon icon={'plus'} />
              <strong>{`New: ${statistics.new}`}</strong>
            </Tag>
            <Tag color={'warning'}>
              <Icon icon={'check'} />
              <strong>{`Active: ${statistics.active}`}</strong>
            </Tag>
            <Tag color={'success'}>
              <Icon icon={'globe'} />
              <strong>{`Production: ${statistics.production}`}</strong>
            </Tag>
            <Tag color={'error'}>
              <Icon icon={'times'} />
              <strong>{`Deactivated: ${statistics.deactivated}`}</strong>
            </Tag>
          </div>
          <div className={'stats'}>
            <Tag color={'success'}>
              <strong>{`4G/BT Connected: ${statistics.connected}`}</strong>
              <strong>{'/'}</strong>
              <strong>{`${statistics.bluetoothConnected}`}</strong>
            </Tag>
            <Tag color={'error'}>
              <strong>{`4G/BT Disconnected: ${statistics.disconnected}`}</strong>
              <strong>{'/'}</strong>
              <strong>{`${statistics.bluetoothDisconnected}`}</strong>
            </Tag>
          </div>
        </Space>
        <div>
          <Space>
            {isOperator ? (
              <RefreshButtonWithTick loadData={loadData} />
            ) : (
              <Button type={'primary'} onClick={loadData}>
                <Icon icon={'sync-alt'} />
                {'Refresh'}
              </Button>
            )}
            {isAdmin && (
              <Button type={'primary'} onClick={handleResetCounters} danger={true}>
                <Icon icon={'redo-alt'} />
                {'Reset counters'}
              </Button>
            )}
          </Space>
        </div>
      </Space>
    ),
    [
      statistics.all,
      statistics.new,
      statistics.active,
      statistics.production,
      statistics.deactivated,
      statistics.connected,
      statistics.bluetoothConnected,
      statistics.disconnected,
      statistics.bluetoothDisconnected,
      loadData,
      isAdmin,
      isOperator,
      handleResetCounters,
    ]
  )

  // Render JSX
  return (
    <Row>
      <Col span={24}>
        <Card title={'List of Lockers'} extra={refreshButton}>
          <Table
            columns={columns} bordered={true} dataSource={data} onRow={onRowProps} loading={loading}
            scroll={{x: 400}}
          />
        </Card>
      </Col>
    </Row>
  )
})

const RefreshButtonWithTick = React.memo(({loadData}: any): JSX.Element => {
  const [tick, setTicks] = useState(60)

  const refresh = useCallback((): void => {
    setTicks(60)
    loadData && loadData()
  }, [loadData])

  const clearAllIntervals = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const intervalId = window.setInterval(() => {
    }, Number.MAX_SAFE_INTEGER)
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < intervalId; i++) {
      window.clearInterval(i)
    }
  }

  useEffect(() => {
    if (tick === 60) {
      clearAllIntervals()
      let counter = 60
      setInterval(() => {
        counter -= 1
        setTicks(counter)
        if (counter === 0) {
          clearAllIntervals()
          refresh()
        }
      }, 1000)
    }
  }, [refresh, tick])

  return (
    <Badge count={tick}>
      <Button type={'primary'} onClick={refresh}>
        <Icon icon={'sync-alt'} />
        {'Refresh'}
      </Button>
    </Badge>
  )
})
