import React, {PropsWithChildren} from 'react'

// Define state type for error boundary component
type ErrorBoundaryState = {
  hasError: boolean
  error?: Error
}

// Define props type for error boundary component
type ErrorBoundaryProps = PropsWithChildren<Record<string, unknown>>

// Error Boundary component
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    // init default local state
    this.state = {hasError: false}
    // Bind this for methods
    this.renderFallbackUI = this.renderFallbackUI.bind(this)
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error}
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
  }

  renderFallbackUI = (error: Error | undefined): JSX.Element => <>{error?.message}</>

  render(): React.ReactNode {
    // destruct state & props
    const {hasError, error} = this.state
    const {children} = this.props
    // check if hasError is true or not
    return !hasError ? children : this.renderFallbackUI(error)
  }
}
