import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome'
import React, {PropsWithChildren} from 'react'

import {MayBeTestable} from './interfaces/mayBeTestable'

// Icon props
export interface IconProps extends PropsWithChildren<FontAwesomeIconProps>, MayBeTestable {
}

// Icon component
export const Icon = React.memo(
  (props: IconProps): JSX.Element => (
    <span data-test={props.dataTest} className={'anticon'}>
      <FontAwesomeIcon {...props} />
      {props.children && <span className={'icon-content'}>{props.children}</span>}
    </span>
  )
)
