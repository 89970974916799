interface LayoutDefinitionConfig {
  span: number
  offset?: number
}

interface LayoutDefinition {
  xl: LayoutDefinitionConfig
  lg: LayoutDefinitionConfig
  md: LayoutDefinitionConfig
  sm: LayoutDefinitionConfig
  xs: LayoutDefinitionConfig
}

interface FormItemLayout {
  labelCol: LayoutDefinition
  wrapperCol: LayoutDefinition
}

type FormLayout = LayoutDefinition

export const DefaultFormItem: FormItemLayout = {
  labelCol: {
    xl: {span: 24},
    lg: {span: 24},
    md: {span: 24},
    sm: {span: 24},
    xs: {span: 24},
  },
  wrapperCol: {
    xl: {span: 24},
    lg: {span: 24},
    md: {span: 24},
    sm: {span: 24},
    xs: {span: 24},
  },
}

export const TinyFormLayout: FormLayout = {
  xl: {span: 12, offset: 6},
  lg: {span: 16, offset: 4},
  md: {span: 18, offset: 3},
  sm: {span: 20, offset: 2},
  xs: {span: 24, offset: 0},
}

export const TinyFormItemLayout: FormItemLayout = {
  labelCol: {
    xl: {span: 6},
    lg: {span: 5},
    md: {span: 4},
    sm: {span: 24},
    xs: {span: 24},
  },
  wrapperCol: {
    xl: {span: 12},
    lg: {span: 14},
    md: {span: 16},
    sm: {span: 24},
    xs: {span: 24},
  },
}

export const TinyFormItemTailLayout: FormItemLayout = {
  labelCol: {
    xl: {span: 6},
    lg: {span: 5},
    md: {span: 4},
    sm: {span: 24},
    xs: {span: 24},
  },
  wrapperCol: {
    xl: {span: 12, offset: 6},
    lg: {span: 14, offset: 5},
    md: {span: 16, offset: 4},
    sm: {span: 24, offset: 0},
    xs: {span: 24, offset: 0},
  },
}
