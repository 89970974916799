type Payload = any
type AnyAction = any

export interface Action {
  type: string
}

export interface ActionWithPayload<T> extends Action {
  payload: T
}

export type Actions = Action | ActionWithPayload<Payload> | AnyAction

export const createAction = (type: string): Action => ({type})
export const createActionWithPayload = <T>(type: string, payload: T): ActionWithPayload<T> => ({type, payload})
