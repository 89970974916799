import {Button, Form, Input, Modal, notification, Space} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {GetProviderParametersRequest, SetProviderParametersRequest} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useCallback, useEffect, useState} from 'react'

// Form props
export interface SetApnFormProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const SetApnForm = ({lockerId, disabled}: SetApnFormProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Form initialization
  const init = useCallback((): void => {
    if (form) {
      form.resetFields()
      form.setFieldsValue({duration: 5000})
    }
  }, [form])

  // Init form
  useEffect(() => init(), [init])

  // Handle reset
  const handleReset = (): void => init()

  // Handle form submit
  const handleFinish = ({operatorName, mcc, mnc, apn, ipProtocol}: Store): void => {
    Modal.confirm({
      title: 'Provider parameters update',
      content: 'The provider parameters will be updated',
      onOk: () => {
        setLoading(true)
        axios
          .setProviderParameters(new SetProviderParametersRequest({
            lockerId,
            cardNumber: 1,
            operatorName,
            mcc,
            mnc,
            apn,
            ipProtocol
          }))
          .then(res => res && setLoading(false))
          .then(() => form.resetFields())
          .then(() => form.setFieldsValue({duration: 5000}))
          .then(() => notification.success({
            message: 'The provider parameters was updated',
            className: 'notify-colored success',
            duration: 4
          }))
          .catch(error =>
            notification.error({
              message: 'Error during updating provider parameters',
              description: error?.detail ?? null,
              className: 'notify-colored error',
              duration: 4,
            })
          )
      },
    })
  }

  const handleLoad = (): void => {
    axios.getProviderParameters(new GetProviderParametersRequest({
      lockerId,
      cardNumber: 1
    })).then(response => form.setFieldsValue(response))
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish} onReset={handleReset}>
      <Form.Item
        name={'operatorName'} required={true} label={'Operator'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input operator'}]}
      >
        <Input disabled={disabled || loading} />
      </Form.Item>
      <Form.Item
        name={'mcc'} required={true} label={'MCC'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input MCC'}]}
      >
        <Input disabled={disabled || loading} />
      </Form.Item>
      <Form.Item
        name={'mnc'} required={true} label={'MNC'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input MNC'}]}
      >
        <Input disabled={disabled || loading} />
      </Form.Item>
      <Form.Item
        name={'apn'} required={true} label={'APN'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input APN'}]}
      >
        <Input disabled={disabled || loading} />
      </Form.Item>
      <Form.Item
        name={'ipProtocol'} required={true} label={'IP Protocol'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input IP Protocol'}]}
      >
        <Input disabled={disabled || loading} />
      </Form.Item>
      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button
            type={'primary'} danger={true} htmlType={'button'} disabled={disabled || loading}
            onClick={handleLoad}
          >
            <Icon icon={'download'} />
            {'Load'}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
