import logo from 'assets/logo.png'
import React from 'react'
import {Link} from 'react-router-dom'

export const Logo = React.memo(
  (): JSX.Element => (
    <div className={'logo'}>
      <Link to={'/lockers'}>
        <img src={logo} alt={'box now'} height={50} />
      </Link>
    </div>
  )
)
