import {Layout} from 'antd'
import React from 'react'
import {Route, RouteComponentProps, RouteProps} from 'react-router-dom'
import {useTitle} from 'react-use'

export interface EmptyLayoutProps extends RouteProps {
  component: any
  title?: string
}

export const EmptyLayout = React.memo(({component: Component, title, ...rest}: EmptyLayoutProps): JSX.Element => {
  useTitle(title ?? '')

  const renderLayout = (props: RouteComponentProps<any>): JSX.Element => (
    <Layout key={'empty-layout'} className={'layout empty'}>
      <Component {...props} />
    </Layout>
  )

  return <Route {...rest} render={renderLayout} />
})
