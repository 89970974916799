import {ConnectionStatus, LockerListDto, LockerState} from 'axios/client'
import {DEFAULT_DATE_TIME_FORMAT} from 'config/client'
import moment from 'moment'
import {Actions, ActionWithPayload, createActionWithPayload} from 'utils/reducer'

import {Locker} from './locker'
import {LockerStatistics} from './lockerStatistics'
import {SearchState} from './searchState'

/* State */
export interface State {
  searchState?: SearchState
  originData?: LockerListDto[]
  data?: Locker[]
  loading: boolean
  statistics: LockerStatistics
}

/* Initial state */
export const initialState: State = {
  loading: false,
  statistics: new LockerStatistics(),
}

/* Action names */
const actionNames = {
  SET_LOADING: 'SET_LOADING',
  SET_SEARCH_STATE: 'SET_SEARCH_STATE',
  DATA_LOADED: 'DATA_LOADED',
  UPDATE_ROW: 'UPDATE_ROW',
}

/* Actions */
export const actions = {
  setLoading: (loading: boolean): ActionWithPayload<boolean> => createActionWithPayload(actionNames.SET_LOADING, loading),
  setSearchState: (state: SearchState): ActionWithPayload<SearchState> => createActionWithPayload(actionNames.SET_SEARCH_STATE, state),
  dataLoaded: (data: LockerListDto[]): ActionWithPayload<LockerListDto[]> => createActionWithPayload(actionNames.DATA_LOADED, data),
  updateRow: (row: LockerListDto): ActionWithPayload<LockerListDto> => createActionWithPayload(actionNames.UPDATE_ROW, row),
}

/* Methods */
const mapLockersWithStatistics = (state: State, dto: LockerListDto[]): State => {
  const data = [] as Locker[]
  const statistics = new LockerStatistics()

  dto?.forEach((locker: LockerListDto) => {
    const lockerData: Locker = {
      key: locker?.uuid ?? '',
      uuid: locker?.uuid ?? '',
      state: locker?.state ?? LockerState.New,
      temperature: locker?.report?.temperature ?? 0,
      connectionStatus: locker?.connectionStatus ?? ConnectionStatus.Disconnected,
      bluetoothConnectionStatus: locker?.bluetoothConnectionStatus ?? ConnectionStatus.Disconnected,
      dateFormatted: moment(locker?.report?.date).utc().format(DEFAULT_DATE_TIME_FORMAT),
      dateFormattedUnix: moment(locker?.report?.date).unix(),
      battery: locker?.report?.batteryLevel ?? 0,
      minBattery: locker?.report?.minBatteryLevel ?? 0,
      minBatteryDateFormatted: moment(locker?.report?.minBatteryLevelDate).utc().format(DEFAULT_DATE_TIME_FORMAT),
      minBatteryDateExists: locker?.report?.minBatteryLevelDate !== undefined && locker?.report?.minBatteryLevelDate !== null && moment(locker?.report?.minBatteryLevelDate).year() > 2000,
      signal: locker?.report?.signalStrength ?? 0,
      arrayVoltage: locker?.report?.arrayVoltage ?? 0,
      loadCurrent: locker?.report?.loadCurrent ?? 0,
      batteryCurrent: locker?.report?.batteryCurrent ?? 0,
      firmwareVersion: locker?.report?.firmwareVersion ?? '',
      doorCount: locker?.doorCount ?? 0,
      offlineInSeconds: locker?.stabilityCounter?.offlineInSeconds ?? 0,
      numberOfConnections: locker?.stabilityCounter?.numberOfConnections ?? 0,
      bluetoothNumberOfConnections: locker?.stabilityCounter?.bluetoothNumberOfConnections ?? 0,
      numberOfFailedCommands: locker?.stabilityCounter?.numberOfFailedCommands ?? 0,
      repeatedSamePinsCount: locker?.stabilityCounter?.repeatedSamePinsCount ?? 0,
      openErrorCount: locker?.stabilityCounter?.openErrorCount ?? 0,
      longOpenedDoors: locker?.longOpenedDoors,
      longOpenedDoorsString: locker?.longOpenedDoors?.toString(),
      longOpenedDoorsCount: locker?.longOpenedDoors?.length ?? 0,
      lastConnectedFormatted: moment(locker?.lastConnected).utc().format(DEFAULT_DATE_TIME_FORMAT),
      lastConnectedFormattedUnix: moment(locker?.lastConnected).unix(),
      waterWarningDoors: locker?.waterWarningDoors,
      errorCodes: locker?.report?.errorCodes ?? [],
      errorCodesString: locker?.report?.errorCodes?.map(x => x.toString(16).padStart(8, '0')).join(', ') ?? 'N/A',
      score: 0,
    }

    let score = 0

    // 4G offline => +50
    if (lockerData.connectionStatus === ConnectionStatus.Disconnected) {
      score += 50
    }

    // baterie < 30 +10; < 40 +5; <50 +1 (baterie 0 => žádné body)
    if (lockerData.battery > 0) {
      if (lockerData.battery < 30) {
        score += 10
      } else if (lockerData.battery < 40) {
        score += 5
      } else if (lockerData.battery < 50) {
        score += 1
      }
    }

    // 4G reconnections > 1000 +5; > 100 +1
    if (lockerData.numberOfConnections > 1000) {
      score += 5
    } else if (lockerData.numberOfConnections > 100) {
      score += 1
    }

    // any error code +10
    if (lockerData.errorCodes?.length > 0) {
      score += 10
    }

    // signal < 12 +5; < 16 +1
    if (lockerData.signal < 12) {
      score += 5
    } else if (lockerData.signal < 16) {
      score += 1
    }

    // teplota > 60 +5
    if (lockerData.temperature > 60) {
      score += 5
    }
    lockerData.score = score
    data.push(lockerData)

    statistics.incrementByState(locker?.state, locker?.connectionStatus, locker?.bluetoothConnectionStatus)
  })

  return {...state, data, statistics, loading: false, originData: dto}
}

const updateRow = (data: LockerListDto[], row: LockerListDto): LockerListDto[] => data.map(item => (item.uuid === row.uuid ? row : item))

/* Reducer */
export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case actionNames.SET_LOADING:
      return {...state, loading: action.payload}
    case actionNames.SET_SEARCH_STATE:
      return {...state, searchState: action.payload}
    case actionNames.DATA_LOADED:
      return mapLockersWithStatistics(state, action.payload)
    case actionNames.UPDATE_ROW:
      return mapLockersWithStatistics(state, updateRow([...(state.originData ?? [])], action.payload))
    default:
      return state
  }
}
