import {Button, Result} from 'antd'
import {Icon} from 'components/icon'
import React from 'react'
import {useHistory} from 'react-router-dom'

export const NotFound = React.memo((): JSX.Element => {
  const history = useHistory()

  const navigateToHomePage = (): void => history.push('/lockers')

  const renderExtra = (): JSX.Element => (
    <Button type={'primary'} onClick={navigateToHomePage} size={'large'}>
      <Icon icon={'arrow-left'} />
      {'Back'}
    </Button>
  )

  return (
    <Result
      status={404} title={'PAGE NOT FOUND'}
      subTitle={'This page does not exists. Return back to the list of lockers'} extra={renderExtra()}
    />
)
})
