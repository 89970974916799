import {Button, Form, InputNumber, Modal, notification, Space} from 'antd'
import {Store} from 'antd/lib/form/interface'
import {AutorestartIntervalRequest, LockerRequest} from 'axios/client'
import axios from 'axios/instance'
import {Icon} from 'components/icon'
import {TinyFormItemLayout, TinyFormItemTailLayout} from 'consts/layout'
import React, {useCallback, useEffect, useState} from 'react'

// Form props
export interface SetReportHardResetProps {
  lockerId: string
  disabled: boolean
}

// Form component
export const SetReportHardResetForm = ({lockerId, disabled}: SetReportHardResetProps): JSX.Element => {
  // Local states
  const [loading, setLoading] = useState(false)

  // Use form reference
  const [form] = Form.useForm()

  // Form initialization
  const init = useCallback((): void => {
    if (form) {
      form.resetFields()
      form.setFieldsValue({duration: 5000})
    }
  }, [form])

  // Init form
  useEffect(() => init(), [init])

  // Handle reset
  const handleReset = (): void => init()

  // Handle form submit
  const handleFinish = ({heartbeatInterval, autorestartInterval, retryInterval}: Store): void => {
    Modal.confirm({
      title: 'Hard reset update',
      content: 'The hard reset will be updated.',
      onOk: () => {
        setLoading(true)
        axios
          .setAutorestartInterval(
            new AutorestartIntervalRequest({
              lockerId,
              heartbeatInterval,
              autorestartInterval,
              retryInterval,
            })
          )
          .then(res => res && setLoading(false))
          .then(() => form.resetFields())
          .then(() => form.setFieldsValue({duration: 5000}))
          .then(() =>
            notification.success({
              message: 'Hard reset was updated',
              className: 'notify-colored success',
              duration: 4,
            })
          )
          .catch(error =>
            notification.error({
              message: 'Error updating hard reset',
              description: error?.detail ?? null,
              className: 'notify-colored error',
              duration: 4,
            })
          )
      },
    })
  }

  const handleLoad = (): void => {
    axios.getAutorestartInterval(new LockerRequest({lockerId})).then(response => form.setFieldsValue(response))
  }

  // Render JSX
  return (
    <Form form={form} onFinish={handleFinish} onReset={handleReset}>
      <Form.Item
        name={'heartbeatInterval'} required={true} label={'Heartbeat'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input heartbeat interval'}]}
      >
        <InputNumber disabled={disabled || loading} min={0} />
      </Form.Item>
      <Form.Item
        name={'autorestartInterval'} label={'Autorestart'} {...TinyFormItemLayout}
        extra={'Leave blank if you want to turn off'}
      >
        <InputNumber disabled={disabled || loading} min={0} />
      </Form.Item>
      <Form.Item
        name={'retryInterval'} required={true} label={'Retry'} {...TinyFormItemLayout}
        rules={[{required: true, message: 'Please input retry interval'}]}
      >
        <InputNumber disabled={disabled || loading} min={0} />
      </Form.Item>
      <Form.Item {...TinyFormItemTailLayout}>
        <Space>
          <Button
            type={'primary'} danger={true} htmlType={'button'} disabled={disabled || loading}
            onClick={handleLoad}
          >
            <Icon icon={'download'} />
            {'Load'}
          </Button>
          <Button type={'primary'} htmlType={'submit'} disabled={disabled || loading}>
            <Icon icon={'paper-plane'} />
            {'Submit'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
